












































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Draggable from 'vuedraggable'
import ExpandIcon from '@/app/ui/assets/expand_icon.vue'
import CloseCircle from '@/app/ui/assets/close_circle_alt.vue'

interface MultiselectValue {
  value: string | number
  label: string
}

/**
 * placeholder: string (optional)
 * Placeholder Text
 *
 * options: array (required)
 * Array of items to render in the component
 *
 * loading: boolean (optional)
 * Loading state
 *
 */
@Component({
  components: {
    ExpandIcon,
    CloseCircle,
    Draggable,
  },
})
export default class Multiselect extends Vue {
  @Prop({ type: String }) placeholder!: string
  @Prop() value!: MultiselectValue[] | null | undefined
  @Prop({ type: Array, required: true }) options!: MultiselectValue[]
  @Prop({ type: Boolean, default: false }) loading!: boolean

  keyword = ''
  active = false

  @Watch('keyword')
  private onSearch(keyword: string): void {
    this.$emit('search', keyword)
  }

  private onFocus() {
    this.active = true
    this.$emit('open', true)
  }

  private onBlur() {
    this.keyword = ''
    this.active = false
    this.$emit('open', false)
  }

  private focusInput() {
    this.active = true

    this.$nextTick(() => {
      const input = this.$refs.input as HTMLInputElement
      input.focus()
    })
  }

  private isSelected(option: MultiselectValue): boolean {
    return this.value?.some(item => {
        return item.value === option.value
      }) as boolean
  }

  private remove(option: MultiselectValue): void {
    this.$emit(
      'input',
      this.value?.filter(val => {
        return val.value !== option.value
      })
    )
  }

  private select(option: MultiselectValue): void {
    if (this.isSelected(option)) {
      return this.remove(option)
    }

    const selected = this.value as MultiselectValue[]
    selected.push(option)

    this.$emit('input', selected)
  }

  private dragStart(event: Event) {
    this.$emit('dragstart', event)
  }

  private dragEnd(event: Event) {
    this.$emit('dragend', event)
  }
}
