





































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import FileInput from '@/app/ui/components/FileInput/index.vue'
import Tooltip from '@/app/ui/components/Tooltip/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import ModalSuccess from '../components/ModalSuccess/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import CloseCircle from '@/app/ui/assets/close_circle_alt.vue'
import controller from '@/app/ui/controllers/CategorySectionController'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import { required, ValidationRule } from 'vuelidate/lib/validators'
import { EventBusConstants, Utils } from '@/app/infrastructures/misc'
import Multiselect from '@/app/ui/components/Multiselect/index.vue'
import MultiselectV2 from '@/app/ui/components/MultiselectV2/index.vue'

interface DropDownInterface {
  value: number
  label: string
}

interface Form {
  categoryName: string
  bannerHomepage: Blob | File | null
  bannerDetail: Blob | File | null
  products: DropDownInterface[]
}

interface ValidationsInterface {
  form: {
    categoryName: {
      required: () => ValidationRule
    },
    bannerHomepage: {
      required: () => ValidationRule
    },
    bannerDetail: {
      required: () => ValidationRule
    },
    products: {
      required: () => ValidationRule
    }
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    Button,
    TextInput,
    FileInput,
    Tooltip,
    CloseCircle,
    Modal,
    ModalSuccess,
    LoadingOverlay,
    Multiselect,
    MultiselectV2
  },
})
export default class CategorySectionCreatePage extends Vue {
  confirmationModal = false
  successModal = false
  enableTooltip = true
  controller = controller

  form: Form = {
    categoryName: '',
    bannerHomepage: null,
    bannerDetail: null,
    products: [],
  }

  created(): void {
    this.fetchProducts('')
  }

  @Watch('controller.statusCreateCategorySection')
  onStatusCreateCategorySectionChanged(status: string): void {
    if (status !== '' && status === EventBusConstants.CREATE_CATEGORY_SECTION_SUCCESS) {
      this.successModal = true
    }
    controller.setStatusCreateCategorySection('')
  }

  get isLoading(): boolean {
    return controller.isLoading
  }

  get productOptions(): DropDownInterface[] {
    return controller.productDropdown.map(product => ({
      value: <number>product.productId,
      label: `${product.productName} - ${product.merchantName}`,
    }))
  }

  get isProductLoading(): boolean {
    return controller.isProductLoading
  }

  private changeBannerHomepage(file: File): void {
    this.form.bannerHomepage = file
  }

  private changeBannerDetail(file: File): void {
    this.form.bannerDetail = file
  }

  @Validations()
  validations(): ValidationsInterface {
    return {
      form: {
        categoryName: { required },
        bannerHomepage: { required },
        bannerDetail: { required },
        products: {
          required,
        },
      },
    }
  }

  private fetchProducts(search: string): void {
    controller.getProductDropdown({ limit: 500, search })
  }

  private productSearch = Utils.debounce((search: string): void => {
    this.fetchProducts(search)
  }, 500)

  private createCategorySection(): void {
    controller.createCategorySection({
      categoryName: this.form.categoryName,
      bannerHomepageImage: this.form.bannerHomepage,
      bannerDetailImage: this.form.bannerDetail,
      products: this.form.products.map(product => product.value),
    })
  }

  private onCloseSuccessModal(): void {
    this.successModal = false
    this.$router.push({ name: 'HomePageCategorySectionList' })
  }

  beforeDestroy(): void {
    controller.setStatusCreateCategorySection('')
  }
}
